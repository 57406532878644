import { camelToSnakeCase, snakeToCamelCase } from "../Lib/caseUtils";
import { notification } from "antd";
import { Address } from "../AddressModal/Address";
import useHeaders from "./useHeaders";
import { LineItem } from "../Cart/LineItem";
import { InvoiceData } from "../common/me";
import { ShippingInfo } from "../common/shippingInfo";
import { invalidate } from "./common";
import { useCallback, useContext } from "react";
import LoginContext from "../AppSetup/LoginContext";
import useUATracker from "./Metrics/useUATracker";
import { ErrorDisplayed } from "./Metrics/UserActions/common";
import { Dayjs } from "dayjs";
import { Subscription } from "./useSettings";
import { TrackingInfo } from "../OrderTracking/models";

interface APIError {
  errorDescription: string;
}

interface NetworkError {
  response: Response;
  data: any;
}

interface LoginResponse {
  authToken: string;
}

interface CheckoutResult {
  checkoutUrl: string;
  status?: string;
}

interface RegistrationRequest {
  phone: string;
  howDidYouFindUs: string;
  marketingConsent: boolean;
  influencerName?: string;
}

interface ContactConsentRequest {
  consent: boolean;
}

interface FetchConsentResponse {
  consent: boolean;
}

interface PauseMembershipRequest {
  membershipId: string;
  until?: Dayjs;
  churnReason?: string;
}

const useAPIClient = () => {
  const { getHeaders } = useHeaders();

  const { trackUserAction } = useUATracker();
  const loginContext = useContext(LoginContext);

  const handleResponse = useCallback(
    <T,>(
      response: Response,
      onError?: (err: NetworkError) => void,
    ): Promise<T | undefined> => {
      return response.text().then((text) => {
        const data = snakeToCamelCase<T>(JSON.parse(text));

        if (!response.ok) {
          if (response.status === 401) {
            if (loginContext !== null) {
              loginContext.logout();
            }
          }
          if (onError !== undefined) {
            try {
              onError({
                response,
                data,
              });
            } catch (err) {
              const errorData = data as APIError;
              const error: string =
                errorData?.errorDescription || response.statusText;
              trackUserAction(new ErrorDisplayed({ errorDescription: error }));
              notification.error({
                message: "Errore",
                description: error,
                duration: 5,
              });
            }
            return;
          } else {
            const errorData = data as APIError;
            const error: string =
              errorData?.errorDescription || response.statusText;
            trackUserAction(new ErrorDisplayed({ errorDescription: error }));
            notification.error({
              message: "Errore",
              description: error,
              duration: 5,
            });
            return;
          }
        }

        return data;
      });
    },
    [loginContext, trackUserAction],
  );

  const get = useCallback(
    async <Type,>(
      url: string,
      params?: { [key: string]: any },
      onError?: (err: NetworkError) => void,
    ) => {
      const requestOptions: RequestInit = {
        method: "GET",
        headers: getHeaders(),
        credentials: "include",
      };
      if (params) {
        const nonNullParams = Object.fromEntries(
          Object.entries(params).filter(([_, value]) => !!value),
        );
        url += `?${new URLSearchParams(
          camelToSnakeCase(nonNullParams),
        ).toString()}`;
      }
      return handleResponse<Type>(
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/web${url}`,
          requestOptions,
        ),
        onError,
      );
    },
    [getHeaders, handleResponse],
  );
  const post = useCallback(
    async <T,>(
      url: string,
      body?: any,
      onError?: (err: NetworkError) => void,
    ) => {
      const requestOptions: RequestInit = {
        method: "post",
        headers: getHeaders(),
        credentials: "include",
        body,
      };
      body = camelToSnakeCase(body);
      if (body) {
        // @ts-ignore
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = JSON.stringify(body);
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/web${url}`,
          requestOptions,
        );
        if (!response.ok) {
          if (onError) {
            onError({
              response,
              data: await response.text(),
            });
          }
        }
        return handleResponse<T>(response);
      } catch (e) {
        notification.error({
          message: "Errore",
          description: "Qualcosa non va! Riprova più tardi",
          duration: 5,
        });
      }
    },
    [getHeaders, handleResponse],
  );

  // -----------------------------------------------------------------------------------
  //  Methods
  // -----------------------------------------------------------------------------------

  const validateAddress = useCallback(
    async (address: Address): Promise<void> => {
      const endpoint = `/validate_address`;
      await post(
        endpoint,
        {
          firstName: address.firstName,
          lastName: address.lastName,
          phone: address.phone,
          address_str: [
            address.address1,
            address.address2,
            address.city,
            address.province,
            address.zip,
          ].join(" "),
        },
        (err) => {
          if (err.data.errorCode === "104") {
            const errMessage =
              "Indirizzo non valido! Prova ad inserire più dettagli (CAP, Provincia, etc.. )";
            trackUserAction(
              new ErrorDisplayed({ errorDescription: errMessage }),
            );
            notification.error({
              message: "Errore",
              description: errMessage,
              duration: 5,
            });
          } else throw err;
        },
      );
      await invalidate("/me");
      await invalidate("/shipping_schedule");
    },
    [post, trackUserAction],
  );

  const getCheckoutUrlFromHubspot = useCallback(
    async (email: string, token: string) => {
      const endpoint = `/cart_from_hubspot?email=${email}&token=${token}`;

      const checkoutResult = await get<CheckoutResult>(endpoint);
      return checkoutResult?.checkoutUrl;
    },
    [get],
  );

  const getSubscriptionCheckoutUrl = useCallback(
    async (
      lineItems: LineItem[],
      shippingInfo: ShippingInfo,
      membership?: string,
      discountCode?: string,
    ) => {
      const endpoint = `/shopify/subscription-checkout`;

      const body = {
        shippingInfo: shippingInfo,
        products: lineItems.map((item) => {
          return { productId: item.product.id, quantity: item.quantity };
        }),
        membership,
        discountCode,
      };
      await invalidate("/me");
      return await post<CheckoutResult>(endpoint, body);
    },
    [post],
  );

  const getMembershipSelfCheckoutUrl = useCallback(
    async (membership: string) => {
      const endpoint = `/shopify/membership-self-checkout`;

      const body = {
        membership,
      };

      interface CheckoutResult {
        checkoutUrl: string;
      }

      return await post<CheckoutResult>(endpoint, body);
    },
    [post],
  );

  const updateDeliveryDate = useCallback(
    async (orderName: string, date: Dayjs) => {
      const endpoint = `/update-delivery-date`;

      const body = {
        orderName,
        newDate: date.format("YYYY-MM-DD"),
      };

      await post(endpoint, body);
      await invalidate("/me");
      await notification.success({
        message: "Data di consegna aggiornata!",
        description: "La data di consegna è stata aggiornata con successo",
        duration: 5,
      });
    },
    [post],
  );

  const getCheckoutUrl = useCallback(
    async ({
      lineItems,
      shippingInfo,
      membership,
      discountCode,
      instalmentSku,
    }: {
      lineItems: LineItem[];
      shippingInfo?: ShippingInfo;
      membership?: string;
      discountCode?: string;
      instalmentSku?: string;
    }) => {
      const endpoint = `/shopify/checkout`;

      const body = {
        shippingInfo: shippingInfo,
        products: lineItems.map((item) => {
          return { productId: item.product.id, quantity: item.quantity };
        }),
        membership,
        discountCode,
        instalmentSku,
      };

      interface CheckoutResult {
        checkoutUrl: string;
      }

      return await post<CheckoutResult>(endpoint, body);
    },
    [post],
  );

  const getMembershipCheckoutUrl = useCallback(
    async (membership: string) => {
      const endpoint = `/shopify/membership-checkout`;

      interface CheckoutResult {
        checkoutUrl: string;
      }

      const checkoutResult = await post<CheckoutResult>(endpoint, {
        membership,
      });
      return checkoutResult?.checkoutUrl;
    },
    [post],
  );

  const getBestSellers = useCallback(
    async (count: number, collectionId?: string) => {
      return await get<String[]>("/best-sellers", { count, collectionId });
    },
    [get],
  );

  const login = useCallback(
    async (email: string, firebaseId: string): Promise<string> => {
      const endpoint = `/login`;
      const loginResponse = await post<LoginResponse>(endpoint, {
        email,
        firebaseId,
      });
      await invalidate("/me");
      await invalidate("/settings");
      if (!loginResponse?.authToken) {
        throw new Error("Auth failed");
      }
      return loginResponse!.authToken;
    },
    [post],
  );

  const setInvoiceData = useCallback(
    async (invoiceData: InvoiceData) => {
      await post("/save_invoice_data", { invoiceData });
      await invalidate("/me");
    },
    [post],
  );

  const forceSegment = useCallback(
    async (experiment: string, segment: number | null) => {
      await post("/force-segment", { experiment, segment }, (err) => {
        notification.error({
          message: "Errore",
          description: "Errore durante il cambio di segmento",
        });
      });
      await invalidate("/settings");
      await invalidate("/me");
      await invalidate("/get-experiments-segments");
    },
    [post],
  );

  const authAs = useCallback(
    async (email: string) => {
      await post("/login-as", { email }, (err) => {
        console.error(err);
      });
      await invalidate("/settings");
      await invalidate("/me");
      await invalidate("/get-experiments");
    },
    [post],
  );

  const completeRegistration = useCallback(
    async ({
      phone,
      howDidYouFindUs,
      marketingConsent,
      influencerName,
    }: RegistrationRequest) => {
      await post("/complete-registration", {
        phone,
        howDidYouFindUs,
        influencerName,
        marketingConsent,
      });
      await invalidate("/me");
    },
    [post],
  );

  const completeContactConsent = useCallback(
    async ({ consent }: ContactConsentRequest) => {
      await post("/submit-contact-consent", { consent });
      await invalidate("/me");
    },
    [post],
  );

  const createCheckoutSession = useCallback(
    async ({
      lineItems,
      shippingInfo,
      membership,
      selectedSub,
      discountCode,
      paymentConfigurationId,
    }: {
      lineItems: LineItem[];
      shippingInfo: ShippingInfo;
      membership?: string;
      selectedSub?: Subscription;
      discountCode?: string;
      paymentConfigurationId?: string;
    }) => {
      const body = {
        products: lineItems.map((item) => {
          return { productId: item.product.id, quantity: item.quantity };
        }),
        membership,
        discountCode,
        shippingInfo,
        paymentConfigurationId,
        subscriptionPriceId: selectedSub?.subscriptionPriceId,
      };

      const response = await post<{
        action: string;
      }>("/stripe/checkout", body);
      if (response === undefined) {
        throw new Error("Errore durante il pagamento");
      }
      await invalidate("/me");
      return response;
    },
    [post],
  );

  const getTrackingInfo = useCallback(
    async ({ orderName }: { orderName: string }) => {
      const endpoint = `/orders/track/${orderName}`;
      return await get<TrackingInfo | null>(endpoint);
    },
    [get],
  );

  const createMembershipIntent = useCallback(
    async ({
      subscriptionPriceId,
      paymentConfigurationId,
    }: {
      subscriptionPriceId: string;
      paymentConfigurationId?: string;
    }) => {
      const body = {
        subscriptionPriceId,
        paymentConfigurationId,
      };
      const response = await post("/stripe/membership-checkout", body);
      if (response === undefined) {
        throw new Error("Errore durante il pagamento");
      }
      await invalidate("/me");
    },
    [post],
  );

  const cancelStripeCheckoutSession = useCallback(async () => {
    await post("/stripe/cancel-checkout-session");
    await invalidate("/me");
  }, [post]);

  const cancelSubscription = useCallback(async () => {
    await post("/cancel-subscription");
    await invalidate("/me");
  }, [post]);

  const pauseMembership = useCallback(
    async ({ membershipId, until, churnReason }: PauseMembershipRequest) => {
      const body = {
        membershipId: membershipId,
        until: until?.format("YYYY-MM-DD"),
        churnReason: churnReason,
      };
      await post("/stripe/pause-membership", body);
      await invalidate("/me");
    },
    [post],
  );

  const fetchMarketingConsent = useCallback(async (): Promise<boolean> => {
    const endpoint = `/fetch-marketing-consent`;
    const consentResponse = await get<FetchConsentResponse>(endpoint);
    if (!consentResponse) {
      throw new Error("Failed to fetch consent");
    }
    return consentResponse.consent;
  }, [get]);

  const submitMarketingConsent = useCallback(
    async (consent: boolean): Promise<void> => {
      const endpoint = `/submit-marketing-consent`;
      await post(endpoint, {
        consent,
      });
      await invalidate("/fetch-marketing-consent");
    },
    [post],
  );

  return {
    get,
    post,
    validateAddress,
    getCheckoutUrl,
    getTrackingInfo,
    getMembershipSelfCheckoutUrl,
    getSubscriptionCheckoutUrl,
    createCheckoutSession,
    createMembershipIntent,
    cancelStripeCheckoutSession,
    getCheckoutUrlFromHubspot,
    getMembershipCheckoutUrl,
    login,
    cancelSubscription,
    pauseMembership,
    setInvoiceData,
    forceSegment,
    authAs,
    getBestSellers,
    completeRegistration,
    completeContactConsent,
    updateDeliveryDate,
    fetchMarketingConsent,
    submitMarketingConsent,
  };
};

export default useAPIClient;

export type { NetworkError, LoginResponse, RegistrationRequest };
