import { useEndpoint } from "./common";
import { LineItem } from "../Cart/LineItem";
import { useServerProducts } from "./useProducts";
import { OrderFulfillmentStatus } from "../common/me";

interface OrderLineItem {
  id: string;
  quantity: number;
  sku: string;
  price: string;
  variantId: string;
}

interface RawOrderType {
  id: string;
  name: string;
  createdAt: string;
  lineItems: OrderLineItem[];
  fulfillmentStatus: OrderFulfillmentStatus;
  cancelledAt: string;
  expectedShippingDate: string;
}

interface OrderType {
  id: string;
  name: string;
  createdAt: string;
  lineItems: LineItem[];
  fulfillmentStatus: OrderFulfillmentStatus;
  cancelledAt?: string;
  expectedShippingDate: string;
}

export const usePastOrders = () => {
  const { products, productsMap } = useServerProducts();
  const { data: orders, isLoading } = useEndpoint<RawOrderType[]>("/orders");

  if (!products) {
    return { orders: undefined, isLoading: true };
  }

  let mappedOrders: OrderType[] | undefined = undefined;
  if (productsMap && orders) {
    mappedOrders = orders?.map((order) => {
      return {
        ...order,
        lineItems: order.lineItems
          .map((li) => {
            const product = productsMap[li.variantId];
            if (!product) {
              return null;
            }
            return {
              product: product,
              quantity: li.quantity,
            };
          })
          .filter((li) => li !== null) as LineItem[],
      };
    });
  }

  return { orders: mappedOrders, isLoading };
};

export type { OrderType };
