import {
  ProductContext,
  ProductContextProps,
  useServerProducts,
} from "../Networking/useProducts";
import { Spin } from "antd";
import { Shop } from "./Shop";

const ShopLoader = () => {
  const { products, isLoading, productsMap } = useServerProducts();
  if (!products || isLoading) {
    return (
      <Spin
        style={{
          color: "#000000",
          width: "100vw",
          height: "20vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  }

  const productContextValue: ProductContextProps = {
    products,
    productsMap,
  };

  return (
    <ProductContext.Provider value={productContextValue}>
      <Shop />
    </ProductContext.Provider>
  );
};

export default ShopLoader;
