import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Menu from "./Layout/Menu";
import React, { useEffect } from "react";
import useDeviceId from "./common/useDevice";
import RegistrationModal from "./RegistrationModal/RegistrationModal";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const { Content } = Layout;

export const Main = () => {
  const { deviceId } = useDeviceId();
  const { lg } = useBreakpoint(true);

  useEffect(() => {
    document
      .getElementById("ot-sdk-btn-floating")
      ?.style.setProperty("display", "none", "important");
  }, []);
  if (!deviceId) {
    throw new Error("Invalid device id context");
  }

  return (
    <Layout
      hasSider={lg ? false : true}
      style={{ width: "100vw", overflowX: "clip" }}
    >
      <Menu />
      <Layout
      // hasSider={lg ? false : true}
      >
        <Content>
          <Outlet />
        </Content>
        <RegistrationModal />
      </Layout>
    </Layout>
  );
};
