import styles from "./styles.module.css";
import ProductModal from "../../Products/ProductModal";
import { Product } from "../../common/product";
import { useState } from "react";
import useUATracker from "../../Networking/Metrics/useUATracker";
import { ProductDetailsOpened } from "../../Networking/Metrics/UserActions/Products";

interface ProductCarouselCardProps {
  product: Product;
}
const ProductCarouselCard = ({ product }: ProductCarouselCardProps) => {
  const [modalOpened, setModalOpened] = useState(false);
  const { trackUserAction } = useUATracker();
  return (
    <div
      onClick={() => {
        setModalOpened(!modalOpened);
        trackUserAction(
          new ProductDetailsOpened({ productName: product.title }),
        );
      }}
    >
      <div
        className={styles.imageContainer}
        onClick={() => setModalOpened(!modalOpened)}
      >
        <img
          src={product.images[0].src}
          alt={product.title}
          className={styles.image}
        />
        <img
          src={"/icons/expand.svg"}
          alt={"Dettagli"}
          className={styles.expandIcon}
        />
      </div>

      <div className={styles.carouselTitle}>{product.title}</div>
      <ProductModal
        product={product}
        onCancel={() => setModalOpened(false)}
        editable={false}
        canAdd={false}
        canRemove={false}
        open={modalOpened}
      />
    </div>
  );
};

export default ProductCarouselCard;
