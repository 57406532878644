enum Constants {
  AUTH_HEADER = "X-Humamy-Auth-Token",
  DEVICE_ID = "X-Humamy-Device-Id",
  FORCED_STORAGE_DEVICE_ID = "Humamy-Force-DeviceId",
  HEADERS = "Humamy-Headers",
  SETTINGS_EXPIRATION = "Humamy-Settings-Expiration",
  SHOPIFY_ACCOUNT = "X-Humamy-Shopify-Account",
  STORAGE_AUTH_TOKEN_KEY = "Humamy-storage-auth-token",
  STORAGE_DEVICE_ID = "Humamy-DeviceId",
  STORAGE_EXPECT_FIREBASE_USER = "Humamy-storage-expect-firebase",
  STORAGE_LINE_ITEMS_KEY = "Humamy-LineItems",
  STORAGE_RECENT_EVENTS = "Humamy-Recent-Events",
  STORAGE_RECENT_FB_EVENTS = "Humamy-Recent-FB-Events",
  STORAGE_SETTINGS = "Humamy-Settings",
  STORAGE_ONBOARDING_KEY = "Humamy-Subscription-Onboarding",
}

export default Constants;
