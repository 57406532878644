import { OrderType } from "../Networking/usePastOrders";
import styles from "./styles.module.css";
import { Card, Collapse } from "antd";
import { OrderTracking } from "../OrderTracking/OrderTracking";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Order = ({ order }: { order: OrderType }) => {
  const { t } = useTranslation();
  const getDate = (stringDate: string) => {
    return new Date(stringDate).toLocaleDateString();
  };

  return (
    <Card
      title={"Ordine del " + getDate(order.createdAt)}
      className={styles.orderCard}
    >
      {t("Data di consegna:")} <b>{getDate(order.expectedShippingDate)}</b>
      <div
        style={{
          marginLeft: -24,
          marginRight: -24,
          borderBottom: "1px #ededed solid",
        }}
      >
        <OrderTracking order={order} />
      </div>
      <Collapse
        ghost
        size="large"
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} />
        )}
        className={styles.collapse + " " + styles.orderCollapse}
        style={{ marginLeft: -24, marginRight: -24, marginTop: 0 }}
        items={[
          {
            label: t("Cosa contiene?"),
            children: order.lineItems.map((li) => (
              <p key={li.product.sku} className={styles.orderRow}>
                {li.product.title} <b>x{li.quantity}</b>
              </p>
            )),
          },
        ]}
      />
    </Card>
  );
};

export default Order;
