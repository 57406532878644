import { useFirebase } from "../firebase/firebase";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import LoginContext from "./LoginContext";
import AppSetupMe from "./AppSetupMe";
import Login from "../Login/Login";
import { LoginSuccessful } from "../Networking/Metrics/UserActions/Login";
import useApiClient from "../Networking/useApiClient";
import useUATracker from "../Networking/Metrics/useUATracker";

enum LoginStatus {
  Loading,
  NotLoggedIn,
  OnlyFirebase,
  LoggedIn,
}

const getLoginStatus = (
  firebaseUserId: string | null,
  loggedIn: boolean,
  expectFirebaseLogin: boolean,
) => {
  if (!!firebaseUserId) {
    if (loggedIn) {
      return LoginStatus.LoggedIn;
    }
    return LoginStatus.OnlyFirebase;
  }
  if (expectFirebaseLogin) {
    return LoginStatus.Loading;
  }
  return LoginStatus.NotLoggedIn;
};

let isCalling = false;

const AppSetupLogin = () => {
  const {
    firebaseUserId,
    firebaseEmail,
    firebaseLogout,
    expectFirebaseUser,
    firebaseProviders,
  } = useFirebase();
  const [loggedIn, setLoggedIn] = useState(false);

  const { login } = useApiClient();
  const { trackUserAction } = useUATracker();

  const loginStatus = getLoginStatus(
    firebaseUserId,
    loggedIn,
    expectFirebaseUser(),
  );

  useEffect(() => {
    const checkLogin = async () => {
      if (!!firebaseUserId && !!firebaseEmail && !loggedIn) {
        if (!isCalling) {
          isCalling = true;
          try {
            await login(firebaseEmail, firebaseUserId);
            setLoggedIn(true);
            console.log("Logged in!");
            // Send a LoginSuccessful UserAction
            trackUserAction(
              new LoginSuccessful({
                email: firebaseEmail,
                loginMethods: firebaseProviders?.join(";"),
              }),
            );
          } catch (error) {
            console.error(error);
            void firebaseLogout();
            return;
          } finally {
            isCalling = false;
          }
        }
      }
    };
    void checkLogin();
  }, [
    firebaseUserId,
    firebaseEmail,
    login,
    loggedIn,
    trackUserAction,
    firebaseLogout,
    firebaseProviders,
  ]);

  if (
    loginStatus === LoginStatus.Loading ||
    loginStatus === LoginStatus.OnlyFirebase
  ) {
    // If login status is OnlyFirebase the login call is in progress
    return (
      <Spin
        style={{
          color: "#000000",
          width: "100vw",
          height: "20vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  }
  if (loginStatus === LoginStatus.LoggedIn) {
    if (!firebaseEmail) {
      throw new Error("Invalid login state");
    }
    return (
      <LoginContext.Provider
        value={{
          email: firebaseEmail,
          logout: () => {
            void firebaseLogout();
            setLoggedIn(false);
          },
          attemptRelogin: () => {
            setLoggedIn(false);
          },
        }}
      >
        <AppSetupMe />
      </LoginContext.Provider>
    );
  }

  // If we got here we are not logged in
  return <Login />;
};

export default AppSetupLogin;
