import { ActionInfo, UserAction } from "../event";

interface ProductDetailsActionInfos extends ActionInfo {
  productName: string;
}

export class ProductDetailsOpened implements UserAction {
  type: string = "product_details_opened";
  actionInfos: ProductDetailsActionInfos;

  constructor(actionInfo: ProductDetailsActionInfos) {
    this.actionInfos = actionInfo;
  }
}

export class ProductDetailsClosed implements UserAction {
  type: string = "product_details_closed";
  actionInfos: ProductDetailsActionInfos;

  constructor(actionInfo: ProductDetailsActionInfos) {
    this.actionInfos = actionInfo;
  }
}

export class OrderProductAdded implements UserAction {
  type: string = "order_product_added";
  actionInfos: ProductDetailsActionInfos;
  metaCustomEventName: string = "AddToCart";

  constructor(actionInfo: ProductDetailsActionInfos) {
    this.actionInfos = actionInfo;
  }
}

export class OrderProductRemoved implements UserAction {
  type: string = "order_product_removed";
  actionInfos: ProductDetailsActionInfos;

  constructor(actionInfo: ProductDetailsActionInfos) {
    this.actionInfos = actionInfo;
  }
}

export type { ProductDetailsActionInfos };
