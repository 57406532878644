import React from "react";
import "../App.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as Sentry from "@sentry/react";
import { ConfigProvider, Spin } from "antd";
import { DeviceContext, useFingerprintJSDeviceId } from "../common/useDevice";
import AppSetupSettings from "./AppSetupSettings";
import LanguageDetector from "i18next-browser-languagedetector";
import it from "../common/translations/it.json";
import de from "../common/translations/de.json";
import en from "../common/translations/en.json";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.001,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const resources = {
  it,
  de,
  en,
};

const AppSetupDevice = () => {
  const { deviceId } = useFingerprintJSDeviceId();

  const detectionOptions = {
    order: ["querystring", "navigator"],
    lookupQuerystring: "lng",
    checkWhitelist: true,
  };

  i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      supportedLngs: ["it", "de", "en"],
      detection: detectionOptions,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });

  if (!deviceId) {
    return (
      <Spin
        style={{
          color: "#FF0000",
          width: "100vw",
          height: "20vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  }

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "sofia-pro, sans-serif;",
            colorBgLayout: "#ffffff",
            // colorTextBase: "black",
            colorPrimary: "#000000",
            colorPrimaryHover: "#000000",
            // screenXXLMin: 2000, // for grid (row/col)
          },
          components: {
            Spin: {
              colorPrimary: "#000000",
            },
            Progress: {
              colorInfo: "var(--green)",
              colorSuccess: "var(--green)",
              borderRadius: 0,
            },
            Collapse: {},
            Button: {
              colorText: "black",
              colorTextLightSolid: "black",
              colorPrimary: "var(--humamy-yellow)",
              // colorPrimaryActive: "var(--humamy-yellow-alt)",
              // colorPrimaryHover: "var(--humamy-yellow-alt)",
              // colorFillContent: "var(--humamy-yellow)",
              // colorFillContentHover: "var(--humamy-yellow-alt)",
              // colorTextHeading: 'black',
              // colorTextLabel: 'black',
              // colorPrimaryText: "black",
              // colorPrimaryTextHover: "black",
              // colorPrimaryTextActive: "black",
              borderRadius: 0,
              // colorBgContainer: "#f5f6fa",
            },
          },
        }}
      >
        <DeviceContext.Provider value={{ deviceId }}>
          <AppSetupSettings />
        </DeviceContext.Provider>
      </ConfigProvider>
    </div>
  );
};

export default AppSetupDevice;
