import React, { useContext, useState } from "react";
import { Product } from "../common/product";
import { Card, Col, Image, Row } from "antd";
import styles from "./styles.module.css";
import { CartContext } from "../Cart/CartContext";
import ProductModal from "./ProductModal";
import {
  ProductDetailsClosed,
  ProductDetailsOpened,
} from "../Networking/Metrics/UserActions/Products";
import useUATracker from "../Networking/Metrics/useUATracker";
import CartButtons from "../Shop/CartButtons";
import PriceBox from "./PriceBox";
import MeContext from "../AppSetup/MeContext";
import { useSettings } from "../Networking/useSettings";
import { useTranslation } from "react-i18next";

interface ProductProps {
  product: Product;
  pricePerItem?: string;
  nonMembershipPricePerItem?: string;
  membershipInCart: boolean;
  inCart?: number;
  onAdd?: () => void;
  onRemove?: () => void;
  editable: boolean;
  maxCartSize?: number;
}

const ProductComponent: React.FC<ProductProps> = ({
  product,
  inCart,
  pricePerItem,
  membershipInCart,
  nonMembershipPricePerItem,
  onAdd,
  onRemove,
  editable,
  maxCartSize,
}) => {
  const [modalOpened, _setModalOpened] = useState(false);
  const { trackUserAction } = useUATracker();
  const meContext = useContext(MeContext);
  const isMember = meContext?.me.isMember;
  const { freemiumMode, subsEnabled, membership } = useSettings();
  const { t } = useTranslation();

  // Prevent scrolling if details modal is opened
  if (modalOpened) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  const setModalOpened = (open: boolean) => {
    const event = open
      ? new ProductDetailsOpened({ productName: product.title })
      : new ProductDetailsClosed({ productName: product.title });
    trackUserAction(event);
    _setModalOpened(open);
  };

  const useMembershipPrice = isMember || membershipInCart;

  const toggleModal = () => {
    setModalOpened(!modalOpened);
  };

  const cart = useContext(CartContext);
  if (!cart && (onAdd || onRemove)) {
    throw new Error(
      "Cannot us ProductComponent in edit mode without a Cart Context",
    );
  }

  let canAdd = (inCart || 0) < product.inventoryQuantity;
  let cantAddReason: string | undefined;
  if (!canAdd) {
    cantAddReason = "Scorte Esaurite";
  }

  if (cart && editable) {
    if (!maxCartSize) {
      throw new Error("Product is editable but no max cart size was passed!");
    }
    const { totalItems } = cart;
    const cartFull = totalItems >= maxCartSize;
    if (cartFull) {
      canAdd = false;
      cantAddReason = "Carrello Pieno!";
    }
  }

  const canRemove = !!inCart && inCart > 0;
  let cantRemoveReason: string | undefined;
  if (!canRemove) {
    cantRemoveReason = "Il prodotto non è nel carrello";
  }

  const membershipExists = !!membership;

  return (
    <Card
      key={product.id}
      className={
        (onAdd || onRemove) && canRemove
          ? styles.productCard + " " + styles.inCart
          : styles.productCard
      }
      bodyStyle={{ padding: 0 }}
    >
      <Row gutter={14}>
        <Col span={11}>
          <Image
            className={styles.image}
            alt={product.images[0].alt}
            src={product.images[0].src}
            placeholder={true}
            preview={false}
            onClick={() => setModalOpened(!modalOpened)}
          />
        </Col>

        <Col span={13} className={styles.details}>
          <div>
            <Row justify="start" onClick={() => setModalOpened(!modalOpened)}>
              <div className={styles.title}>
                <span>{product.title}</span>
              </div>
            </Row>

            {pricePerItem && !subsEnabled && (
              <Row justify={"start"} style={{ width: "100%" }}>
                {pricePerItem && (
                  <PriceBox
                    price={pricePerItem}
                    highlight={useMembershipPrice}
                    strikethrough={membershipExists && !useMembershipPrice}
                    premium={!!freemiumMode}
                  />
                )}
                {membershipExists &&
                  freemiumMode &&
                  nonMembershipPricePerItem && (
                    <PriceBox
                      price={nonMembershipPricePerItem}
                      highlight={!useMembershipPrice}
                      strikethrough={useMembershipPrice}
                      premium={false}
                    />
                  )}
              </Row>
            )}
          </div>

          {!onAdd && !onRemove && (
            <Row
              justify="space-between"
              style={{ width: "100%" }}
              onClick={() => setModalOpened(!modalOpened)}
            >
              <div className={styles.detailsBtn}>{t("Dettagli")}</div>
              {inCart! > 0 && (
                <div className={styles.qtyLabel}> x {inCart} </div>
              )}
            </Row>
          )}

          {onAdd && onRemove && (
            <Row
              align="middle"
              justify="space-between"
              gutter={20}
              style={{ width: "100%" }}
            >
              <div
                className={styles.detailsBtn}
                style={{ marginLeft: 10 }}
                onClick={() => setModalOpened(!modalOpened)}
              >
                {t("Dettagli")}
              </div>

              <CartButtons product={product} isInCart={false} />
            </Row>
          )}
        </Col>
      </Row>
      <ProductModal
        product={product}
        inCart={inCart}
        onCancel={toggleModal}
        editable={editable}
        onAdd={onAdd}
        canAdd={canAdd}
        cantAddReason={cantAddReason}
        cantRemoveReason={cantRemoveReason}
        onRemove={onRemove}
        canRemove={canRemove}
        open={modalOpened}
      />
    </Card>
  );
};

export default ProductComponent;
