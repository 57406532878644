import styles from "../Shop/Unlimited/styles.module.css";
import React from "react";
import { useMe } from "../AppSetup/MeContext";
import { useTranslation } from "react-i18next";
import { useSettings } from "../Networking/useSettings";
import { useLocation } from "react-router-dom";

const WebsiteBanners = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { latestOrder, stripeMemberships, isUnlimitedMember } = useMe();
  const { unlimited, unlimitedEnabled } = useSettings();

  const pauseRenewalDate = stripeMemberships.filter(
    (membership) => membership.membershipId === unlimited?.membershipId,
  )[0]?.scheduledRenewalDate;

  const UnlimitedBanners = () => {
    return (
      !isUnlimitedMember &&
      location.pathname === "/shop/unlimited-paywall" && (
        <>
          {!latestOrder && (
            <div className={styles.smallBanner}>
              {t("Sconto di 200€ sul primo mese!")}
            </div>
          )}
          {pauseRenewalDate && (
            <div className={styles.smallBanner}>
              La pausa finirà il {pauseRenewalDate} e l'abbonamento verrà
              rinnovato.
            </div>
          )}
        </>
      )
    );
  };

  return <>{unlimitedEnabled ? UnlimitedBanners() : null}</>;
};

export default WebsiteBanners;
