import { Col, Row, Space, Spin } from "antd";
import { useServerProducts } from "../Networking/useProducts";
import ProductComponent from "../Products/Product";
import PageTitle from "../PageTitle/PageTitle";
import { useTranslation } from "react-i18next";

interface ProductsViewProps {
  editable: boolean;
}

export const ProductsView = ({ editable }: ProductsViewProps) => {
  const { t } = useTranslation();
  const { products } = useServerProducts();

  const renderProducts = () => {
    if (!products) {
      return (
        <Spin
          style={{
            color: "#000000",
            width: "100vw",
            height: "20vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      );
    }
    return products.map((product) => (
      <ProductComponent
        membershipInCart={false}
        inCart={0}
        editable={editable}
        key={product.id}
        product={product}
      />
    ));
  };

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <PageTitle>{t("Il nostro menu")}</PageTitle>
      <Row justify="center">
        <Col span={8} xs={24} md={12} xl={8}>
          {renderProducts()}
        </Col>
      </Row>
    </Space>
  );
};

export default ProductsView;
