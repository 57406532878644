import React, { useState } from "react";
import { Button, Col, Collapse, Form, Input, Row, Space, Spin } from "antd";
import styles from "./styles.module.css";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithPopup,
} from "firebase/auth";
import useUATracker from "../Networking/Metrics/useUATracker";
import { LoginEmailConfirmationSent } from "../Networking/Metrics/UserActions/Login";
import { auth } from "../firebase/firebase";
import GoogleButton from "react-google-button";
import { DownOutlined, FacebookFilled } from "@ant-design/icons";
import { useSettings } from "../Networking/useSettings";
import { Trans, useTranslation } from "react-i18next";

enum LoginStatus {
  Idle,
  Loading,
  WaitForEmail,
}

const Login: React.FC = () => {
  const [loginStatus, setLoginStatus] = useState<LoginStatus>(LoginStatus.Idle);

  const { enableGoogleLogin, enableFacebookLogin } = useSettings();

  const { t } = useTranslation();

  const { trackUserAction } = useUATracker();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | undefined>();

  const facebookLogin = () => {
    const provider = new FacebookAuthProvider();
    provider.addScope("public_profile,email");

    signInWithPopup(auth, provider).catch((error) => {
      setError(error.message);
    });
  };

  const googleLogin = () => {
    const provider = new GoogleAuthProvider();
    provider.addScope("email");

    signInWithPopup(auth, provider).catch((error) => {
      setError(error.message);
    });
  };

  const onSubmit = async (values: any) => {
    console.log(values);
    const email = values.email && values.email.trim().toLowerCase();
    console.log(email);
    setEmail(email);
    setLoginStatus(LoginStatus.Loading);
    trackUserAction(new LoginEmailConfirmationSent({ email }));
    const domain = window.location.protocol + "//" + window.location.host;
    await sendSignInLinkToEmail(auth, email, {
      url: encodeURI(`${domain}/?email=${email}`),
      // This is just a firebase thing.
      handleCodeInApp: true,
    });
    setLoginStatus(LoginStatus.WaitForEmail);
  };

  const renderSocialLogins = () => {
    if (!enableFacebookLogin && !enableGoogleLogin) {
      return null;
    }
    return (
      <Collapse
        ghost
        size="large"
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} />
        )}
        className={styles.collapse}
        items={[
          {
            key: "invoice-form",
            label: t("Altri metodi di login"),
            children: (
              <>
                {enableGoogleLogin && (
                  <GoogleButton
                    type="light"
                    className={styles.socialLoginBtn}
                    label={t("Continua con Google")}
                    onClick={googleLogin}
                  />
                )}
                {enableFacebookLogin && (
                  <Button
                    icon={<FacebookFilled style={{ color: "#1f72ff" }} />}
                    className={styles.socialLoginBtn}
                    onClick={facebookLogin}
                  >
                    {t("Continua con Facebook")}
                  </Button>
                )}
              </>
            ),
          },
        ]}
      />
    );
  };

  return (
    <Row justify={"center"}>
      {loginStatus === LoginStatus.WaitForEmail ? (
        <Space direction={"vertical"}>
          <img alt={"logo"} className={styles.logoTop} src={"/logo.png"} />

          <div className={styles.center}>
            <img src="/icons/mail.svg" alt="mail" />
            <h1 className={styles.title}>{t("Ottimo!")}</h1>
            <div className={styles.textCenter}>
              <Trans i18nKey={"emailSent"} email={email}>
                Ti abbiamo inviato una mail all'indirizzo {{ email }} per
                confermare il tuo account. Clicca sul link nella mail per
                accedere!
              </Trans>
              <h3 className={styles.error}>
                <Trans i18nKey={"spamAlert"} email={email}>
                  Attenzione! Recentemente abbiamo ricevuto segnalazioni di
                  moltissime email che finiscono in spam. <br />
                  Se non trovi la mail, controlla la cartella spam o scrivi a
                  &nbsp;
                  <a className={styles.mail} href="mailto:scrivici@humamy.com">
                    scrivici@humamy.com
                  </a>
                </Trans>
              </h3>
            </div>
          </div>

          <p className={styles.textMail}>
            <Trans i18nKey={"noMailReceived?"} mailClass={styles.mail}>
              <b>Non hai ricevuto nessuna mail?</b> <br />
              Scrivi alla tua expert oppure a: <br />
              <a className={styles.mail} href="mailto:scrivici@humamy.com">
                scrivici@humamy.com
              </a>
            </Trans>
          </p>
        </Space>
      ) : (
        <Form
          onFinish={onSubmit}
          disabled={loginStatus === LoginStatus.Loading}
        >
          <div className={styles.imgDesktop} />
          <Row className={styles.loginBanner}>
            <Col xs={0} md={12} />
            <Col xs={24} md={12}>
              {t("Entra e gusta subito i nostri piatti!")}
            </Col>
          </Row>

          <Space
            direction={"vertical"}
            align="center"
            size={0}
            className={styles.rightArea}
          >
            <img
              alt={"delicious dishes"}
              className={styles.imgMobile}
              src={"/images/login-mobile-thin.jpg"}
            />

            <div className={styles.bottom}>
              <img
                alt={"logo Humamy"}
                className={styles.logo}
                src={"/logo.png"}
              />
              <p className={styles.textIntro + " " + styles.dark}>
                {t("Inserisci la tua mail per registrarti o fare il login.")}
              </p>
              <div className={styles.form}>
                <p className={styles.textLeft}>Email</p>
                <Form.Item
                  name="email"
                  className={styles.formSize}
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={t("Es. nome@email.com")}
                    className={styles.input}
                  />
                </Form.Item>
                <Form.Item>
                  {loginStatus === LoginStatus.Loading ? (
                    <Spin className={styles.spinner} />
                  ) : (
                    <Button
                      className={styles.button + " " + styles.primary}
                      type="primary"
                      htmlType="submit"
                    >
                      {t("Continua")}
                    </Button>
                  )}
                </Form.Item>
                {renderSocialLogins()}
                {error && <p className={styles.error}>{error}</p>}
              </div>

              <p
                className={styles.textIntro + " " + styles.dark}
                style={{
                  fontSize: 13,
                  margin: "0px 16px 24px",
                  lineHeight: "130%",
                }}
              >
                <Trans i18nKey={"termsAndConditions"}>
                  Continuando, accetti i nostri
                  <a
                    href={t("termsAndConditionsUrl")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Termini e condizioni di servizio
                  </a>
                  e confermi di aver ricevuto la nostra
                  <a
                    href={t("privacyPolicyUrl")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Informativa sulla privacy e sui cookie
                  </a>
                  .
                </Trans>
              </p>
            </div>
          </Space>
        </Form>
      )}
    </Row>
  );
};

export default Login;
