import { Me } from "../common/me";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { invalidate } from "../Networking/common";

interface MeContextProps {
  me: Me;
  isSuperUser: boolean;
  setIsSuperUser: (isSuperUser: boolean) => void;
  isUnlimitedMember: boolean;
}

const MeContext = createContext<MeContextProps | null>(null);

const useMe = (refresh_timeout_ms?: number) => {
  const context = useContext(MeContext);
  if (context === null) {
    throw new Error("useMe must be used within a MeContextProvider");
  }
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    if (refresh_timeout_ms) {
      const timeout = setTimeout(() => {
        void invalidate("/me");
        setToggle(!toggle);
      }, refresh_timeout_ms);
      return () => clearTimeout(timeout);
    }
  }, [toggle, refresh_timeout_ms]);

  return useMemo(() => {
    return {
      ...context.me,
      isSuperUser: context.isSuperUser,
      setIsSuperUser: context.setIsSuperUser,
      isUnlimitedMember: context.isUnlimitedMember,
    };
  }, [context]);
};

export default MeContext;
export { useMe };
export type { MeContextProps };
