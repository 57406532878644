import { useMe } from "../AppSetup/MeContext";
import { Col, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "antd/es/layout/layout";
import shopStyles from "../Shop/styles.module.css";
import { useTranslation } from "react-i18next";

export const PaymentSucceeded = () => {
  const me = useMe(2000);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!me.pendingCheckoutSession) {
      navigate("/");
    }
  }, [me, navigate]);

  return (
    <Row justify="center">
      <Header
        style={{
          width: "100%",
          padding: 0,
          zIndex: 1,
          height: 55,
          marginTop: -40,
          backgroundColor: "transparent",
        }}
      >
        <div className={shopStyles.titleCentered} id="title">
          {t("Pagamento")}
        </div>
      </Header>
      <Col span={20} style={{ textAlign: "center", marginTop: 50 }}>
        <img src={"/icons/check_illu.svg"} alt={"check icon"} />
        <h1 style={{ fontSize: 18, color: "var(--green)" }}>
          {t("Pagamento completato")}. <br />
          {t("Grazie")} {me.firstName}! <br />
          {me.pendingCheckoutSession?.mode === "payment"
            ? t("Il tuo ordine è in preparazione.")
            : t("Stiamo configurando il tuo abbonamento.")}
          <br />
        </h1>
        <Spin
          style={{
            color: "#000000",
            width: "100%",
            height: "10vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </Col>
    </Row>
  );
};
