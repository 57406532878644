import React, { useEffect, useMemo } from "react";
import { Spin } from "antd";
import { useSettings } from "../Networking/useSettings";
import { useMe } from "../AppSetup/MeContext";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const Home = () => {
  const { enableTastingBox, subscriptions } = useSettings();
  const me = useMe();
  const navigate = useNavigate();

  const yesterday = useMemo(() => dayjs().subtract(1, "day"), []);

  useEffect(() => {
    if (me.isUnlimitedMember && me.latestOrder) {
      navigate("/unlimited-recap");
      return;
    }
    if (
      subscriptions &&
      subscriptions.length > 0 &&
      !me.subscriptionProfile &&
      (!me.latestOrder ||
        dayjs(me.latestOrder?.expectedShippingDate) < yesterday)
    ) {
      navigate("/shop/choose-subscription");
      return;
    }
    if (subscriptions && subscriptions.length > 0 && me.subscriptionProfile) {
      navigate("/shop/subscription-recap");
      return;
    }
    if (enableTastingBox && !me.latestOrder) {
      navigate("/shop/tasting-box");
      return;
    }
    if (
      me.latestOrder &&
      dayjs(me.latestOrder?.expectedShippingDate) > yesterday
    ) {
      navigate("/shop/order-recap");
      return;
    }
    navigate("/shop/edit");
  }, [
    navigate,
    enableTastingBox,
    me.latestOrder,
    subscriptions,
    me.subscriptionProfile,
    yesterday,
    me.isUnlimitedMember,
  ]);

  return (
    <Spin
      style={{
        color: "#000000",
        width: "100vw",
        height: "20vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );
};

export default Home;
